import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import "@fontsource/noto-serif-jp"

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: '山の幸、美味しく食べて健康に。山懐に抱かれ、花と緑に囲まれた静かな宿です。四季折々の諸塚産食材でおもてなし致します。' },
              { name: 'keywords', content: '森の民宿 樹の里,宮崎,諸塚,山の幸' },
            ]}
          >
            <html lang="ja" />
            <meta name="description" content="山の幸、美味しく食べて健康に。山懐に抱かれ、花と緑に囲まれた静かな宿です。四季折々の諸塚産食材でおもてなし致します。" />
            <meta property="og:site_name" content="森の民宿 樹の里" />
            <meta property="og:title" content="森の民宿 樹の里" />
            <meta property="og:description" content="山の幸、美味しく食べて健康に。山懐に抱かれ、花と緑に囲まれた静かな宿です。四季折々の諸塚産食材でおもてなし致します。" />
            <meta property="og:url" content="https://kinosato.org" />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="ja_JP" />
            <meta property="og:image" content="https://kinosato.org/thumb-kinosato.jpg" />
            <meta property="pg:image:width" content="1280" />
            <meta property="pg:image:height" content="640" />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel='canonical' href='https://kinosato.org' />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
